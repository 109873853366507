<template>
  <ValidationObserver v-slot="{ invalid }">
    <form id="form-login" class="form-horizontal form-control-borderless">
      <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="col-xs-12">
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-envelope-o"></i></span>
              <input
                v-model="User_Email"
                autocomplete="off"
                type="text"
                id="login-email"
                name="login-email"
                class="form-control input-lg"
                placeholder="Enter email"
              />
            </div>
            <div v-show="errors[0]" class="animation-slideUp help-block">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </ValidationProvider>
      <div class="from-group">
        <div class="col-xs-12 text-left">
          <router-link to="/signin"><p class="text-forgot">Return SignIn</p></router-link>
        </div>
      </div>
      <div class="form-group ">
        <div class="col-xs-12 text-center">
          <button type="button" class="btn btn-sm btn-login" @click="handleForgot">
            Send mail
          </button>
        </div>
      </div>

      <div class="form-group">
        <div class="col-xs-12 text-center text-waring">
          <router-link to="/signup"
            ><button class="btn btn-sm btn-sub">Create an account</button></router-link
          >
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { forgot } from "@/services/auth";

export default {
  name: "ForgotForm",
  components: {},
  data: () => ({
    User_Email: "",
    token: ""
  }),
  methods: {
    async handleForgot() {
      this.$startLoading();
      await this.$recaptchaLoaded();
      this.token = await this.$recaptcha("auth");
      const { data, status, message, errors } = await forgot(this.User_Email, this.token);

      this.$finishLoading();
      if (status) {
        this.$router.push({
          name: "signin"
        });
        this.$toast.success(message, {});
      } else {
        this.$toast.error(message, {});
        if (errors !== null) {
          for (const property in errors) {
            let content = `${errors[property]}`;
            this.$toast.error(message, {});
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-login {
  &__recaptcha {
    display: flex;
    justify-content: center;
  }
}
</style>
