<template>
  <auth-layout>
    <template #title>Forgot password</template>
    <forgot-form></forgot-form>
  </auth-layout>
</template>

<script>
import ForgotForm from "@/components/auth/ForgotForm";
import { isLoggedIn } from "@/services/auth";
import AuthLayout from "./layouts/Auth";

export default {
  name: "Forgot",
  components: {
    AuthLayout,
    ForgotForm
  },
  beforeCreate() {
    if (isLoggedIn()) this.$router.push({ name: "forgot" });
  }
};
</script>
