<template>
  <div class="auth">
    <div id="login-container" class="animation-fadeIn">
      <div class="block push-bit" style="padding: 15px">
        <div class="login-title text-center">
          <h1>
            <strong>
              <slot name="title">Sign In</slot>
            </strong>
          </h1>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
};
</script>
<style scoped>
.block {
  background: #e2e8f091;
  padding: 15px;
}
</style>
<style>
#form-login {
  padding: 5px 15px;
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input-group {
  /* box-shadow: 1px 2px 9px 2px #1213148a; */
  border-radius: 5px;
}
.input-group-addon {
  height: 40px;
}
.input-lg {
  height: 40px;
}
p.text-forgot {
  color: #f6e700;
  font-size: 17px;
  margin: 10px;
  font-weight: bold;
}
</style>
